// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';

$(() => {
  var previousRandomLight = null
  var alphabet = 'abcdefghijklmnopqrstuvwxyz$§1234567890!#€%&/()=*^~'.split('');
  var letter = $( '.grid__cell' );
  var letterAnim = $( '.grid__cell.active' );
  var delay = Math.floor(Math.random()*100+200);

  function runtime() {

    var random = Math.floor(Math.random() * (letter.length - 1)) + 2;
    var randomLight = letter[random];

    letter.each(function(){
        $(this).html(alphabet[~~(Math.random()*alphabet.length)]);
    });

    if (previousRandomLight) previousRandomLight.classList.toggle('active')
    randomLight.classList.toggle('active');
    previousRandomLight = randomLight;

    setTimeout(function(){
      runtime();
    }, 500);

  }

  runtime();

  setTimeout(function(){
    $('body').addClass('loaded')
  }, 500);

});
